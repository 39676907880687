import type { ContentDiscoveryAllSlotNames } from "@internal/utils-content-discovery-zone";
// As we add more configuration members, add them here.
export interface MinFontSize {
    [key: string]: number;
}

export interface ProductGroupConfiguration {
    hasAiTemplatesEnabled: boolean;
    isBlankDocumentValidationEnabled: boolean;
    isTeamsExperiementGroupMember: boolean;
}

/**
 * This defines the expected API result from the Design Experience Manager (DXM)
 * service.
 */
export interface DesignExperienceManagementState {
    productMinDpi: string; // drives image resolution validation
    shouldFlipArrows: boolean; // flip arrows when duplicating front
    shouldShowQRCodes: boolean;
    shouldShowImages: boolean;
    shouldShowGraphics: boolean;
    shouldShowTables: boolean;
    shouldShowBackgroundColor: boolean;
    showChangeSize: boolean;

    // If a product has photographic review scenes then PURCS will only return the photographic scenes that are configured
    // We may end up not getting scenes for canvases.  We fill in those scenes with transient ones as a fallback
    // Some products do not want this behavior.  They have photograghic scenes defined that include multiple design areas
    shouldUseTransientSceneFallback: boolean;
    shouldUseTrimPaths: boolean;
    shouldUseBetweenBoundariesValidation: boolean;
    shouldUseOutsideMarginsValidation: boolean;

    minFontSize?: MinFontSize | null;
    itemPreviewMixedBlendMultiply: boolean; // similar to multiply mode in scenes, will not show white
    isCompatibleForMailingService: boolean;
    openFirstFullColorSurface: boolean; // instead of defaulting to the first canvas, default to first full-color canvas
    initialZoomFactor: number; // Not being used in studio 6  (Also in Default Config)
    useReactVortexPreview: boolean;
    showChangeTemplate: boolean;
    useColorGenericBacksides: boolean;
    useProductOptionTools: boolean; // used in flexibility ab test
    autoRemoveBackground: boolean; // Implement in studio 6 when this feature is added
    enablePanelSections?: boolean; // Injects panel section data into the design engine for the 3D dex
    enableCutlineGenerationExperience?: boolean; // Enables cutline generation experience
    useSceneOnChangeSizePreviewImage: boolean;
    useGalleryTemplateForBacksides: boolean;
    enable3DExperience?: boolean; // Enables 3d view next to canvas
    forceServerSideRendering?: boolean; // Forces server side rendering, used for previews
    /**
     * Flag to enable/disable patterns
     */
    shouldShowPatterns: boolean;
    "studio-routing"?: {
        external: string;
        embedded: string;
    };

    /**
     * Below represents properties that exist on DXM configuration but are not actively used by Studio
     * today.
     */
    cropStrategy?: unknown;
    flipLineLocation?: unknown;
    useCanvasChangePopover?: unknown;
    allowDynamicUnderlayScene?: unknown;
    "template-routing"?: unknown;
    "quad-routing"?: unknown;
    "x-sell-routing"?: unknown;
    "post-sell-routing"?: unknown;
}

/**
 * This interface defines state managed by Studio itself, outside of the DXM service.
 */
export interface CustomStudioConfigurationState {
    // If you add to this, be sure to rebuild the library 'pnpm build:libs'.
    // You may also need to restart TypeScript server or reload window.
    /** Manage tools */
    shouldShowTeams: boolean;
    shouldShowText: boolean;
    shouldShowCutline?: boolean;
    shouldHideImageDiscoveryTab?: boolean;
    disableContextualTools?: boolean;
    shouldShowToolDivider: boolean;
    enableToggleBackground?: boolean;
    supportsAdjustContrast?: boolean;
    disableDesignReview?: boolean;

    /** Manage experience */
    connectedDesignArea?: "Default" | "StickerDEX";
    useSingleImageUploadWithCutlineGeneration?: boolean;
    shouldSaveAdaptStickerDocument?: boolean;
    openSelectImageModalOnLoad?: boolean;
    disableKeyboardShortcuts?: boolean;
    onlyShowChecklistImageItem?: boolean;
    useUnitPricing?: boolean;
    useUploadAndGoHelpButton?: boolean;
    disableViewControls?: boolean;
    disableFlexibilityPanelPreviews?: boolean;
    disableFileBrowserDragNDrop?: boolean;
    showDesignPanel?: boolean;
    hideCDZ?: boolean;
    /** Flag to enable/disable option to change finish */
    shouldAllowToChangeFinish: boolean;

    /** Default Active Dialog State */
    defaultSlotName?: ContentDiscoveryAllSlotNames;
    /** Enable / Disable design assistant tab  */
    isDesignAssistantTabEnabled?: boolean;

    shouldAllowUserSave: boolean;
    shouldAllowRenameDesign: boolean;
    shouldAllowDuplicateDesign: boolean;
    shouldAllowMyProjects: boolean;
}

export interface DesignExperienceManagementAndProductGroupConfigStates
    extends DesignExperienceManagementState,
        ProductGroupConfiguration {}

export interface InitialStudioConfigurationState
    extends CustomStudioConfigurationState,
        DesignExperienceManagementAndProductGroupConfigStates {}

export const DEFAULT_DXM_DATA = {
    productMinDpi: "300",
    shouldShowQRCodes: true,
    shouldShowImages: true,
    shouldShowGraphics: true,
    shouldShowTables: true,
    shouldShowBackgroundColor: true,
    shouldFlipArrows: false,
    shouldUseTrimPaths: false,
    shouldUseBetweenBoundariesValidation: true,
    shouldUseTransientSceneFallback: true,
    shouldUseOutsideMarginsValidation: true,
    openFirstFullColorSurface: false,
    isCompatibleForMailingService: false,
    useReactVortexPreview: false,
    initialZoomFactor: 1,
    showChangeTemplate: true,
    showChangeSize: true,
    useSceneOnChangeSizePreviewImage: false,
    useGalleryTemplateForBacksides: true,
    useColorGenericBacksides: true,
    useProductOptionTools: true,
    autoRemoveBackground: false,
    itemPreviewMixedBlendMultiply: true,
    enablePanelSections: false,
    enableCutlineGenerationExperience: false,
    enable3DExperience: false,
    forceServerSideRendering: false,
    shouldShowPatterns: false
} satisfies DesignExperienceManagementState;

export const DEFAULT_PRODUCT_GROUP_CONFIGURATION = {
    hasAiTemplatesEnabled: false,
    isBlankDocumentValidationEnabled: false,
    isTeamsExperiementGroupMember: false
} satisfies ProductGroupConfiguration;

export const DEFAULT_CUSTOM_STUDIO_CONFIGURATION = {
    shouldShowTeams: false,
    disableDesignReview: false,
    shouldShowText: true,
    connectedDesignArea: "Default" as CustomStudioConfigurationState["connectedDesignArea"],
    defaultSlotName: "Text" as NonNullable<CustomStudioConfigurationState["defaultSlotName"]>,
    shouldShowToolDivider: true,
    disableFileBrowserDragNDrop: false,
    showDesignPanel: true,
    isDesignAssistantTabEnabled: false,
    hideCDZ: false,
    shouldAllowUserSave: true,
    shouldAllowRenameDesign: true,
    shouldAllowDuplicateDesign: true,
    shouldAllowMyProjects: true,
    shouldAllowToChangeFinish: true
} satisfies CustomStudioConfigurationState;

export const DEFAULT_CONFIGURATION_DATA = {
    ...DEFAULT_DXM_DATA,
    ...DEFAULT_PRODUCT_GROUP_CONFIGURATION,
    ...DEFAULT_CUSTOM_STUDIO_CONFIGURATION
} satisfies InitialStudioConfigurationState;

/**
 * @note, for some reason, if you add a `satisfies` clause to a value (as above), TS infers the
 * types for booleans as the literal value `true | false` instead of just `boolean`. Oddly enough,
 * it doesn't do this for other types like string or numbers.
 *
 * This utility type iterates over all the values in the type (`T`) and widens the type for
 * any boolean literals (`true` or `false`) to the wider type, `boolean`.
 */
type WidenBooleanType<T extends object> = { [K in keyof T]: T[K] extends true | false ? boolean : T[K] };

export type DefaultStudioConfigurationState = WidenBooleanType<typeof DEFAULT_CONFIGURATION_DATA>;

/**
 * This is the final configuration state that StudioConfigurationManager emits.
 * It combines types from DXM, CustomStudioConfigurationState, and then the
 * default state. It takes preference to types provided by the default state
 * (`DefaultStudioConfigurationState`) by omitting types from the other two
 * where there is overlap.
 */
export interface StudioConfigurationState
    extends Omit<CustomStudioConfigurationState, keyof DefaultStudioConfigurationState>,
        Omit<DesignExperienceManagementState, keyof DefaultStudioConfigurationState>,
        DefaultStudioConfigurationState {}

/** Design Attributes */
export interface DesignAttributeMapping {
    designAttributeName: string;
    designAttributeValue: string;
    productOptionName: string;
    productOptionValue: string;
}

export interface MappedProductOption {
    designAttributeName?: string;
    designAttributeValue?: string;
    productOptionName: string;
    productOptionValue: string;
}

export interface CompatibleProductOption {
    name: string;
    values: string[];
}
