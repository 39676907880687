import React, { createContext, useCallback, useContext, useMemo, useState, useEffect } from "react";
import { useAbTestContext, ExperimentData } from "@internal/ab-test-framework";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";
import { useStudioLayout } from "@internal/feature-responsive-design";

type ContextData = {
    isCanvasTextEditingEnabled: boolean;
    trackImpressionForCanvasTextEditing: () => void;
};

const enabledProductCategories = ["Business Cards", "Marketing Materials"];

const context = createContext<ContextData | undefined>(undefined);

export function useCanvasTextEditing() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a CanvasTextEditingABProvider");
    }
    return result;
}

enum Variations {
    Control = "control",
    Test = "test"
}

export const CanvasTextEditingExperimentData: ExperimentData = {
    experimentKey: "prioritize_canvas_text_editing",
    experimentName: "Canvas Text Editing",
    variations: Variations
};

export const CanvasTextEditingABProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { experimentKey } = CanvasTextEditingExperimentData;
    const [isEnabled, setIsEnabled] = useState(false);
    const [isTrackingComplete, setIsTrackingComplete] = useState(false);
    const { productCategory } = useProductAndProjectStateManager().data;
    const { isSmall } = useStudioLayout();

    useEffect(() => {
        setIsTrackingComplete(false);
    }, [productCategory]);

    useEffect(() => {
        if (!experimentKey || !ABTest || isSmall) {
            return;
        }
        const { isExperimentUsingVariation } = ABTest;

        const checkEnabledVariation = async () => {
            const res = await isExperimentUsingVariation(experimentKey, Variations.Test);
            const enabled = res && enabledProductCategories.includes(productCategory);
            setIsEnabled(!!enabled);
        };
        checkEnabledVariation();
    }, [experimentKey, ABTest, productCategory, isSmall]);

    const trackImpressionForCanvasTextEditing = useCallback(() => {
        if (!experimentKey || !ABTest || isTrackingComplete || isSmall) {
            return;
        }

        const trackImpressionIfInVariant = async (variation: Variations) => {
            const { isExperimentUsingVariation, trackImpression } = ABTest;
            const res = await isExperimentUsingVariation(experimentKey, variation);

            if (res && enabledProductCategories.includes(productCategory)) {
                trackImpression(experimentKey, variation);
            }
        };
        trackImpressionIfInVariant(Variations.Test);
        trackImpressionIfInVariant(Variations.Control);
        setIsTrackingComplete(true);
    }, [ABTest, experimentKey, isTrackingComplete, productCategory, isSmall]);

    const contextObject = useMemo(
        () => ({
            isCanvasTextEditingEnabled: isEnabled,
            trackImpressionForCanvasTextEditing
        }),
        [isEnabled, trackImpressionForCanvasTextEditing]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

CanvasTextEditingABProvider.displayName = "CanvasTextEditingABProvider";
