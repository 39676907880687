var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { BaseExtension, EXECUTE_COMMAND_TOKEN } from "@design-stack-vista/interactive-design-engine-core";
import { action, makeObservable, observable, computed, flow, flowResult } from "mobx";
import { isVectorUrl } from "../shared/utils/vectorItemTypeGuard.js";
import { getPanelColors } from "../shared/utils/getPanelColors.js";
import { getPanel } from "../shared/utils/getPanel.js";
import "@design-stack-vista/utility-core";
import "@design-stack-vista/core-features";
import "@design-stack-vista/cimdoc-state-manager";
import { getFullColorOverridesWithDefaultValues } from "@internal/utils-color";
import { ItemReferenceTypes } from "@internal/utils-custom-metadata";
import { setColorOverrides, colorOverridesMatch } from "../commands/setColorOverrides.js";
import { NewrelicError, getScopedNewrelicWrapper } from "@internal/utils-newrelic";
import { ERROR_CODES } from "@internal/utils-errors";
import { NR_SCOPE } from "../constants.js";
var __defProp2 = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--) if (decorator = decorators[i]) result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result) __defProp2(target, key, result);
  return result;
};
function catchCancellation(promise) {
  promise.catch(() => {
  });
  return promise;
}
const colorsCache = /* @__PURE__ */ new Map();
const newrelic = /* @__PURE__ */ getScopedNewrelicWrapper(NR_SCOPE);
class VectorExtension extends BaseExtension {
  constructor(designState, executeCommand) {
    super(designState);
    __publicField(this, "status", "loading");
    __publicField(this, "pendingColorRead");
    this.executeCommand = executeCommand;
    makeObservable(this);
    this.pendingColorRead = /* @__PURE__ */ catchCancellation(/* @__PURE__ */ flowResult(/* @__PURE__ */ this.extractImageColors()));
  }
  static supports(designState) {
    return designState.isItemReference() && designState.model.type === ItemReferenceTypes.VECTOR_PANEL && isVectorUrl(designState.model.url);
  }
  get subpanelUrl() {
    const {
      model
    } = this.designState;
    return model.data.subpanelUrl;
  }
  get colorOverrides() {
    const {
      model
    } = this.designState;
    return model.data.colorOverrides;
  }
  get colors() {
    return this.colorOverrides.slice(0, 4).map((item) => {
      return Object.entries(item)[0][0];
    });
  }
  *extractImageColors() {
    this.status = "loading";
    const {
      id: vectorId
    } = this.designState;
    try {
      if (colorsCache.has(this.subpanelUrl)) {
        this.executeCommand((cimdoc) => {
          setColorOverrides(cimdoc, vectorId, /* @__PURE__ */ getFullColorOverridesWithDefaultValues(/* @__PURE__ */ colorsCache.get(this.subpanelUrl)), true);
        }, {});
      } else {
        const panel = yield getPanel(this.subpanelUrl);
        const colors = /* @__PURE__ */ getPanelColors(panel);
        if (colors == null ? void 0 : colors.length) {
          const overrides = /* @__PURE__ */ getFullColorOverridesWithDefaultValues(colors);
          const match = /* @__PURE__ */ colorOverridesMatch(this.designState.model.data.colorOverrides, overrides, true);
          if (!match) {
            colorsCache.set(this.subpanelUrl, colors);
            this.executeCommand((cimdoc) => {
              setColorOverrides(cimdoc, vectorId, overrides, true);
            }, {});
          }
        }
      }
      this.status = "ready";
    } catch (e) {
      newrelic.noticeError(new NewrelicError("Error extracting Vector colors", {
        source: "client",
        code: ERROR_CODES.VECTORS,
        error: e
      }), {
        vectorId,
        eventDetail: "extractImageColors"
      });
      this.status = "failed";
    }
  }
  changeColor(newOverrides) {
    const {
      id: vectorId
    } = this.designState;
    this.executeCommand((cimdoc) => {
      setColorOverrides(cimdoc, vectorId, newOverrides, false);
    }, {});
  }
  get isReady() {
    return this.status === "ready";
  }
  get isLoading() {
    return this.status === "loading";
  }
  get isFailed() {
    return this.status === "failed";
  }
  dispose() {
    super.dispose();
    this.pendingColorRead.cancel();
  }
}
__publicField(VectorExtension, "inject", [EXECUTE_COMMAND_TOKEN]);
__decorateClass([observable], VectorExtension.prototype, "status", 2);
__decorateClass([computed], VectorExtension.prototype, "subpanelUrl", 1);
__decorateClass([computed], VectorExtension.prototype, "colorOverrides", 1);
__decorateClass([computed], VectorExtension.prototype, "colors", 1);
__decorateClass([flow], VectorExtension.prototype, "extractImageColors", 1);
__decorateClass([action.bound], VectorExtension.prototype, "changeColor", 1);
__decorateClass([computed], VectorExtension.prototype, "isReady", 1);
__decorateClass([computed], VectorExtension.prototype, "isLoading", 1);
__decorateClass([computed], VectorExtension.prototype, "isFailed", 1);
export {
  VectorExtension
};
