import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useAbTestContext, ExperimentData } from "@internal/ab-test-framework";
import { useProductAndProjectStateManager } from "@internal/utils-product-and-project-state";

type ContextData = {
    isTextOnAPathABEnabled: boolean;
    trackImpressionForTextOnAPath: () => void;
};

const enabledProductCategory = "Business Cards";

const context = createContext<ContextData | undefined>(undefined);

export function useTextOnAPath() {
    const result = useContext(context);
    if (!result) {
        throw Error("Missing context. This must be called within a TextOnAPathABProvider");
    }
    return result;
}

enum Variations {
    Control = "control",
    Test = "test"
}

export const TextOnAPathExperimentData: ExperimentData = {
    experimentKey: "studio_text_on_a_path",
    experimentName: "Studio Text On A Path",
    variations: Variations
};

export const TextOnAPathABProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const { Provider } = context;
    const ABTest = useAbTestContext();
    const { experimentKey } = TextOnAPathExperimentData;
    const [isEnabled, setIsEnabled] = useState(true); // Default to true so that the tool makes room for itself before loading test state
    const [isTrackingComplete, setIsTrackingComplete] = useState(false);
    const { productCategory } = useProductAndProjectStateManager().data;

    const trackImpressionForTextOnAPath = useCallback(() => {
        if (!experimentKey || !ABTest || isTrackingComplete) {
            return;
        }

        const trackImpressionIfInVariant = async (variation: Variations) => {
            const { isExperimentUsingVariation, trackImpression } = ABTest;
            const res = await isExperimentUsingVariation(experimentKey, variation);
            if (res && productCategory === enabledProductCategory) {
                trackImpression(experimentKey, variation);
                setIsEnabled(variation === Variations.Test);
            }
            // Because we default to enabled (so that the toolbar doesn't shift), disable the test when we're not in the test
            else if (variation === Variations.Test) {
                setIsEnabled(false);
            }
        };

        trackImpressionIfInVariant(Variations.Test);
        trackImpressionIfInVariant(Variations.Control);
        setIsTrackingComplete(true);
    }, [ABTest, experimentKey, isTrackingComplete, productCategory]);

    const contextObject = useMemo(
        () => ({
            isTextOnAPathABEnabled: isEnabled,
            trackImpressionForTextOnAPath
        }),
        [isEnabled, trackImpressionForTextOnAPath]
    );

    return <Provider value={contextObject}>{children}</Provider>;
};

TextOnAPathABProvider.displayName = "TextOnAPathABProvider";
