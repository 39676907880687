import { action, computed, makeObservable, observable } from "mobx";
import {
    DEFAULT_CONFIGURATION_DATA,
    type DefaultStudioConfigurationState,
    type StudioConfigurationManager,
    SimConfigurationCache,
    StudioDesignExperienceExtension,
    applyDeveloperOverrides,
    applyDesignExperienceExtensionOverrides,
    applyDecoTechUXOverrides,
    InitialStudioConfigurationState,
    type StudioConfigurationManagerInitConfig
} from "@internal/utils-studio-configuration-core";
import { DecorationTechnology } from "@internal/utils-deco-tech";
import { ContentDiscoveryAllSlotNames, ContentDiscoveryZoneSlotDivider } from "@internal/utils-content-discovery-zone";
import z from "zod";
import type { VCSDexSchema } from "../sims";
import { applyVCSModeOverrides, CustomVCSData, GLOBAL_VCS_CONFIG_MODIFICATIONS } from "./applyVCSModeOverrides";
import {
    allLargeScreencontentDiscoveryZoneOrderedSlots,
    allSmallScreencontentDiscoveryZoneOrderedSlots
} from "./contentDiscoveryZoneOrderedSlots";

type VCSSimCache = SimConfigurationCache<VCSDexSchema>;

const VCS_DEFAULT_CONFIGURATION_DATA = {
    ...DEFAULT_CONFIGURATION_DATA,
    // VCS may disable certain configurations either globally or per editing mode
    // here we default these to be disabled so there's no change of a feature flashing available before being disabled
    // or reset back to an undesired state
    ...GLOBAL_VCS_CONFIG_MODIFICATIONS,
    // This will get overriden later depending on shopper mode in applyVcsModeOverrides,
    // but we don't want to default it to true, or else there will be a flicker
    // turning these buttons on/off if they are later disabled
    // These do not belong in GLOBAL_VCS_CONFIG_MODIFICATIONS, because they do not apply to admin
    shouldShowBackgroundColor: false,
    shouldShowPatterns: false
} satisfies InitialStudioConfigurationState;

export class VCSStudioConfigurationManager implements StudioConfigurationManager {
    @observable
    private _studioConfigState: InitialStudioConfigurationState = {
        ...Object.freeze(VCS_DEFAULT_CONFIGURATION_DATA)
    };

    @observable
    private _simCache: VCSSimCache = new SimConfigurationCache();

    @observable
    private _decorationTechnology = DecorationTechnology.Unknown;

    @observable
    private _studioDXExtensions = new Set<StudioDesignExperienceExtension>();

    @observable
    private _locale?: string;

    @observable
    private _isTeamsProduct = false;

    @observable
    private _customConfig?: CustomVCSData;

    constructor() {
        // initial state for sim configuration
        this._simCache.set("@internal/advanced-editor-sim-text", {
            addNewTextItem: [],
            allowDeleteText: false
        });

        this._simCache.set("@internal/advanced-editor-sim-image", {
            allowDeleteImage: false
        });

        makeObservable(this);
    }

    @action
    updateDecorationTechnology(decorationTechnology: DecorationTechnology) {
        this._decorationTechnology = decorationTechnology;
    }

    @action
    /* istanbul ignore next */
    updateCurrentFinish(currentFinish: string): void {
        // This is a no-op
        return;
    }

    simData<Key extends keyof VCSDexSchema = keyof VCSDexSchema>(simName: Key, schema: z.AnyZodObject) {
        return this._simCache.get(simName, schema);
    }

    @computed
    get data() {
        const result = this._studioConfigState;
        result.shouldShowTeams = this._isTeamsProduct;

        return applyVCSModeOverrides(
            applyDeveloperOverrides(
                applyDesignExperienceExtensionOverrides({
                    baseConfig: applyDeveloperOverrides(
                        applyDecoTechUXOverrides(result, this._decorationTechnology, this._locale),
                        this._studioDXExtensions
                    ),
                    decorationTechnology: this._decorationTechnology,
                    extensions: this._studioDXExtensions
                }),
                this._studioDXExtensions
            ),
            this._customConfig
        );
    }

    // We currently do not want to show any slots for VCS
    // Flags can be added here as well as the slot names file
    private getOrderedSlots(allSlots: Array<ContentDiscoveryAllSlotNames | ContentDiscoveryZoneSlotDivider>) {
        const slotsToFill: Array<ContentDiscoveryAllSlotNames | ContentDiscoveryZoneSlotDivider> = [];
        return slotsToFill;
    }

    @computed
    get largeScreencontentDiscoveryZoneOrderedSlots() {
        return this.getOrderedSlots(allLargeScreencontentDiscoveryZoneOrderedSlots);
    }

    @computed
    get smallScreencontentDiscoveryZoneOrderedSlots() {
        return this.getOrderedSlots(allSmallScreencontentDiscoveryZoneOrderedSlots);
    }

    private handleUpdateByKey = <Key extends keyof InitialStudioConfigurationState>(
        key: Key,
        data: Partial<InitialStudioConfigurationState>
    ) => {
        const result = data[key];

        /* istanbul ignore else -- @preserve */
        if (result !== undefined) {
            this._studioConfigState[key] = result;
        }
    };

    private updateDiffs(data: Partial<InitialStudioConfigurationState>) {
        const currentStateKeys = Object.keys(this._studioConfigState) as Array<keyof InitialStudioConfigurationState>;
        const newKeys = Object.keys(data) as Array<keyof InitialStudioConfigurationState>;
        /**
         * @note: Instead of setting `this._studioConfigState = data`, instead we iterate over each
         * key in the new data set and assign it to the corresponding value
         * of the base object. This results in more optimal updates for MobX
         * because it can observe changes to the individual properties of
         * `this._studioConfigState`. If we replaced the entire reference of `this._studioConfigState`,
         * by setting `this._studioConfigState = data`, it results in rerendering every component
         * that observes any property of `this._studioConfigState`.
         */
        for (const key of newKeys) {
            this.handleUpdateByKey(key, data);
        }

        /**
         * Next, we need to update values back to the default state if they were not specified in `data`.
         */
        const defaultKeys = Object.keys(VCS_DEFAULT_CONFIGURATION_DATA) as (keyof DefaultStudioConfigurationState)[];

        /**
         * Finally, we need to remove any keys from the currrent data that do not exist in the
         * the new `data`, unless the key is included in the defaults, for which we return to the default
         */
        for (const currentKey of currentStateKeys) {
            if (newKeys.includes(currentKey) === false) {
                if (defaultKeys.includes(currentKey as keyof DefaultStudioConfigurationState)) {
                    this.handleUpdateByKey(currentKey, VCS_DEFAULT_CONFIGURATION_DATA);
                } else {
                    delete this._studioConfigState[currentKey];
                }
            }
        }
    }

    @action
    init(config: StudioConfigurationManagerInitConfig, customConfig: CustomVCSData): void {
        const configForCurrentMode: Partial<InitialStudioConfigurationState> = { ...GLOBAL_VCS_CONFIG_MODIFICATIONS };

        if (customConfig.url.mode === "shopper" && !!customConfig.url.brandedProductId) {
            configForCurrentMode.shouldShowBackgroundColor = false;
            configForCurrentMode.shouldShowPatterns = false;
        }

        this.updateDiffs({
            ...config.designExperienceManagementState,
            ...config.productGroupConfigurationState,
            ...configForCurrentMode
        });

        // item operations like add/delete are allowed for admins, or for shoppers not editing a branded product
        const allowItemOperations =
            customConfig.url.mode === "admin" ||
            (customConfig.url.mode === "shopper" && !customConfig.url.brandedProductId);

        this._simCache.set("@internal/advanced-editor-sim-text", {
            addNewTextItem: allowItemOperations ? ["text"] : [],
            allowDeleteText: allowItemOperations
        });

        this._decorationTechnology = config.decorationTechnology;
        this._studioDXExtensions = config.studioDXExtensions;
        this._locale = config.locale;
        this._isTeamsProduct = config.isTeamsProduct;
        this._customConfig = customConfig;
    }
}
