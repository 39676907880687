import { getRandomPositionWithinArea } from "@design-stack-vista/utility-core";
import { useDesignEngine } from "@design-stack-vista/core-features";
import { addVectorItem } from "../commands/addVectorItem.js";
import { mergeColorOverrides } from "../shared/utils/mergeColorOverrides.js";
import { COLOR_SWATCHES_LIMIT } from "../constants.js";
import "@design-stack-vista/cimdoc-state-manager";
import "@internal/utils-custom-metadata";
import { getPanelColors } from "../shared/utils/getPanelColors.js";
import { getPanel } from "../shared/utils/getPanel.js";
import { getDefaultSizeForShape, getLargestSafeArea } from "../shared/utils/shapeUtils.js";
import { getInitialColorOverrides, getFullColorOverridesWithDefaultValues } from "@internal/utils-color";
const getPosition = (designEngine, currentPanel, naturalDimensions, safeAreaDimensions, location) => {
  const {
    width,
    height
  } = /* @__PURE__ */ getDefaultSizeForShape({
    currentPanel,
    designEngine,
    initialDimensions: naturalDimensions
  });
  const {
    x,
    y
  } = /* @__PURE__ */ getRandomPositionWithinArea(safeAreaDimensions, {
    width,
    height
  });
  return {
    width: `${width}mm`,
    height: `${height}mm`,
    x: `${location ? location.x - width / 2 : safeAreaDimensions.x + x}mm`,
    y: `${location ? location.y - height / 2 : safeAreaDimensions.y + y}mm`
  };
};
const getColorOverrides = (colorValues) => {
  return getInitialColorOverrides(colorValues).slice(0, COLOR_SWATCHES_LIMIT);
};
const getColorOverridesFromSubpanel = async (colorValues, subpanelUrl) => {
  const panelTask = /* @__PURE__ */ getPanel(subpanelUrl);
  const initialColorOverrides = /* @__PURE__ */ getInitialColorOverrides(colorValues);
  const panel = await panelTask;
  const colors = /* @__PURE__ */ getPanelColors(panel);
  const overrides = /* @__PURE__ */ getFullColorOverridesWithDefaultValues(colors);
  return mergeColorOverrides(initialColorOverrides, overrides, true).slice(0, COLOR_SWATCHES_LIMIT);
};
function useAddVector() {
  const designEngine = /* @__PURE__ */ useDesignEngine();
  const {
    executeCommand,
    idaStore,
    cimDocStore
  } = designEngine;
  const currentPanelId = idaStore.activeDesignPanelId ?? designEngine.cimDocStore.panels[0].id;
  const currentPanel = /* @__PURE__ */ cimDocStore.panels.find((p) => p.panelProperties.id === currentPanelId);
  if (!currentPanel) {
    throw new Error(`no active panel`);
  }
  const safeAreaDimensions = /* @__PURE__ */ getLargestSafeArea(designEngine, currentPanel);
  return async (subpanelUrl, colorValues, naturalDimensions, location) => {
    const position = /* @__PURE__ */ getPosition(designEngine, currentPanel, naturalDimensions, safeAreaDimensions, location);
    const colorOverrides = colorValues.length ? await getColorOverridesFromSubpanel(colorValues, subpanelUrl) : getColorOverrides(colorValues);
    const {
      commandOutput
    } = /* @__PURE__ */ executeCommand(addVectorItem, {
      position,
      panelId: idaStore.activeDesignPanelId ?? designEngine.cimDocStore.panels[0].id,
      data: {
        subpanelUrl,
        colorOverrides
      }
    });
    const newItem = commandOutput;
    idaStore.setSelectedIds([newItem.id]);
  };
}
export {
  useAddVector
};
