import { Events, useTrackingClient } from "@internal/utils-tracking";
import { useCallback } from "react";
import { useAddVector } from "../../hooks/useAddVector.js";
import { PAGE_ACTION_EVENTS, getScopedNewrelicWrapper } from "@internal/utils-newrelic";
import { NR_SCOPE } from "../../constants.js";
const newrelic = /* @__PURE__ */ getScopedNewrelicWrapper(NR_SCOPE);
const useHandleDropTarget = () => {
  const onAddVector = /* @__PURE__ */ useAddVector();
  const trackingClient = /* @__PURE__ */ useTrackingClient();
  return useCallback((config) => {
    const {
      height,
      width,
      subpanelUrl,
      colorValues
    } = config.dropped.item;
    onAddVector(subpanelUrl, colorValues, {
      width,
      height
    }, config.position);
    trackingClient.track(Events.DesignToolUsed, {
      eventDetail: "drag:vector:add",
      label: "Drag clipart",
      extraData: () => ({
        item: config.dropped.item
      })
    });
    newrelic.addPageAction(PAGE_ACTION_EVENTS.ContentDiscoveryZoneInteraction, {
      eventDetail: "drag:vector:add",
      panel: /* @__PURE__ */ JSON.stringify(config.position),
      item: /* @__PURE__ */ JSON.stringify(config.dropped.item)
    });
  }, [onAddVector, trackingClient]);
};
export {
  useHandleDropTarget
};
